<script>
import { convertEtaToDate, formatDate } from '../../helpers/date';
import { updateEnvironment } from '../../firebase/api';
import eventHub, { EVENTS } from '../../store/eventHub';

export default {
  props: {
    env: { type: String, required: true },
    isLoading: { type: Boolean, required: true, default: true },
    envConfig: { type: Object, required: true },
  },
  data() {
    return {
      isExtendModalVisible: false,
      isTimePickerVisible: false,
      isDatePickerVisible: false,
      isSnackbarVisible: false,
      isExtending: false,
      errorMessage: '',
      form: {
        etaDate: '',
        etaTime: '',
      },
    };
  },
  computed: {
    shouldBeAvailable() {
      return new Date() > new Date(this.envConfig.eta);
    },
    envStatus() {
      if (this.envConfig.status === 'AVAILABLE') {
        return { color: 'green', text: 'AVAILABLE' };
      }

      if (this.shouldBeAvailable) {
        return { color: 'orange', text: 'IDLE' };
      }

      return { color: 'red', text: 'OCCUPIED' };
    },
    bgImage() {
      return `background-image: url(${this.getArtwork()})`;
    },
    tooltipText() {
      const status = this.envStatus.text;

      if (status === 'AVAILABLE') {
        return 'You should be good to deploy to this environment';
      }

      if (status === 'OCCUPIED') {
        return 'You may not be able to deploy to this environment';
      }

      return `This environment should be good to be deployed. Contact ${this.envConfig.account.displayName} before doing it so.`;
    },
  },
  methods: {
    generateRandomInteger(min, max) {
      return Math.floor(min + Math.random() * (max - min + 1));
    },
    extend() {
      if (!this.form.etaDate || !this.form.etaTime) {
        this.isSnackbarVisible = true;
        this.errorMessage = 'Some data is missing. Please complete all required fields';

        return;
      }

      const eta = convertEtaToDate(this.form.etaDate, this.form.etaTime);

      updateEnvironment({ eta, env: this.envConfig.env, status: '' })
        .then(() => {
          this.isExtendModalVisible = false;
          eventHub.$emit(EVENTS.REFRESH_REQUESTED);
        });
    },
    makeAvailable() {
      updateEnvironment({
        status: 'AVAILABLE',
        env: this.envConfig.env,
      })
        .then(() => {
          eventHub.$emit(EVENTS.REFRESH_REQUESTED);
        });
    },
    getArtwork() {
      return `/images/illustrations/all/${this.generateRandomInteger(1, 25)}.svg`;
    },
    formatDate,
  },
};
</script>

<template>
  <v-col cols="12" md="4">
    <v-card
      class="summary-card"
      :loading="isLoading"
      elevation="1"
      outlined
    >
      <v-list-item>
        <v-list-item-content>
          <div v-if="isLoading" class="spacer" />
          <div
            v-else-if="Object.keys(envConfig).length === 0"
            class="spacer"
          >
            <h1 class="envTitle">
              {{ env }}
            </h1>
            <p class="d-flex align-center justify-center no-data">
              No data
            </p>
          </div>
          <template v-else>
            <h1 class="env-title">
              {{ envConfig.env }}

              <v-tooltip bottom max-width="360">
                <template #activator="{ on, attrs }">
                  <v-chip
                    :color="envStatus.color"
                    dark
                    small
                    class="chip"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ envStatus.text }}
                  </v-chip>
                </template>
                <span>{{ tooltipText }}</span>
              </v-tooltip>
            </h1>

            <p class="subtitle mb-2">
              <img src="/images/icons/spaceship.svg" width="16" />
              Last deployment
            </p>
            <p class="font-weight-medium mb-3">
              {{ envConfig.account.displayName }}
              <span class="mx-1">•</span>
              {{ formatDate(envConfig.createdAt) }}
            </p>
            <p class="font-weight-medium">
              ETA
              <span class="mx-1">•</span>
              {{ formatDate(envConfig.eta) }}
            </p>
            <p class="divider" />
            <p class="links mb-2">
              <img src="/images/icons/github.svg" />
              <a :href="envConfig.pr">GitHub PR</a>
            </p>
            <p class="links">
              <img src="/images/icons/jira.svg" />
              <a :href="envConfig.task">Jira Task</a>
            </p>
            <div class="artwork-container" :style="bgImage" />
          </template>
        </v-list-item-content>
      </v-list-item>
      <v-menu
        v-if="!isLoading"
        bottom
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            light
            icon
            v-bind="attrs"
            class="actions-menu-launcher"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            link
            @click="makeAvailable"
          >
            <v-list-item-title>
              Make Available
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="isExtendModalVisible = true"
          >
            <v-list-item-title>
              Extend
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card>
    <v-dialog
      v-model="isExtendModalVisible"
      max-width="640"
      persistent
    >
      <v-card>
        <v-toolbar
          light
          color="grey lighten-4"
          elevation="1"
        >
          <v-toolbar-title class="font-weight-medium">
            Extend {{ envConfig.env }} environment
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            light
            @click="isExtendModalVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid class="mt-4 pb-7">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="isDatePickerVisible"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.etaDate"
                        label="ETA (Date)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        hide-no-data
                        hide-details
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="form.etaDate"
                      first-day-of-week="1"
                      :min="new Date().toISOString()"
                      @input="isDatePickerVisible = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="isTimePickerVisible"
                    :close-on-content-click="false"
                    :return-value.sync="form.etaTime"
                    transition="scale-transition"
                    offset-y
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.etaTime"
                        label="ETA (Time)"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        outlined
                        hide-no-data
                        hide-details
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="isTimePickerVisible"
                      v-model="form.etaTime"
                      format="24hr"
                      :min="`${new Date().getHours()}:${new Date().getMinutes()}`"
                      :allowed-minutes="(m) => m % 5 === 0"
                      @click:minute="$refs.menu.save(form.etaTime)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="grey darken-4"
                large
                class="white--text"
                :loading="isExtending"
                :disabled="isExtending"
                @click="extend"
              >
                <v-icon left>
                  mdi-timer-plus-outline
                </v-icon>
                Extend
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-snackbar
        v-model="isSnackbarVisible"
      >
        {{ errorMessage }}
        <template #action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isSnackbarVisible = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </v-col>
</template>

<style lang="scss">
.summary-card {
  background-image: url('../../../public/images/logo-drop.png');

  .spacer {
    height: 226px;
  }

  .env-title {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 24px !important;
  }

  .chip {
    top: -3px;
  }

  .subtitle {
    font-size: 14px;

    img {
      top: 2px;
      margin-right: 4px;
      position: relative;
    }
  }

  .divider {
    width: 70px;
    height: 1px;
    display: inline-block;
    flex: none;
    border: 1px dashed #EEEEEE;
    margin: 12px 0 16px !important;
  }

  .no-data {
    height: 170px;
  }

  .links {
    font-size: 14px;

    img {
      position: relative;
      top: 1px;
    }

    a {
      text-decoration: none;
      margin: 8px;
    }
  }

  .artwork-container {
    width: 170px;
    height: 170px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 100%;
    box-shadow: -5px 7px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-size: 90%;
    background-position: center;
  }

  .actions-menu-launcher {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }

  @media only screen and (min-width: 960px) and (max-width: 1300px)  {
    .artwork-container {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
