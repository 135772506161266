<script>
import DeployModal from '../deploy/DeployModal.vue';

export default {
  components: {
    DeployModal,
  },
  data() {
    return {
      isDeployModalVisible: false,
    };
  },
};
</script>

<template>
  <div class="app-bar">
    <v-app-bar
      light
      color="grey lighten-4"
      elevation="1"
    >
      <img
        src="https://superpeer.com/images/brandassets/superpeer-logo-hand.png"
        width="28"
        class="logo"
        alt="Superpeer Logo"
      />
      <v-toolbar-title>Launch Control</v-toolbar-title>
      <v-spacer />
      <deploy-modal />
    </v-app-bar>
  </div>
</template>

<style lang="scss">
.app-bar {
  .v-toolbar__title {
    font-weight: 500;
    font-size: 18px;
  }

  img.logo {
    margin-right: 8px;
  }
}
</style>
