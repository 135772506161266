<script>
import AppBar from '../components/layout/AppBar.vue';
import SummaryBar from '../components/summary/SummaryBar.vue';
import DeployList from '../components/deploy/DeployList.vue';

export default {
  components: {
    AppBar,
    SummaryBar,
    DeployList,
  },
};
</script>

<template>
  <div>
    <app-bar />
    <div class="app-content-wrapper">
      <summary-bar />
      <h3 class="font-weight-light mt-6 mb-2">
        Deployments
      </h3>
      <deploy-list />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-content-wrapper {
  padding: 16px;
}
</style>
