import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import initFirebase from './firebase/init';
import { registerAuthStateListener } from './services/auth';

Vue.config.productionTip = false;

initFirebase();
registerAuthStateListener();

window.app = new Vue({
  router,
  vuetify,
  render: (h) => { return h(App); },
}).$mount('#app');
