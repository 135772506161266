<script>
import eventHub, { EVENTS } from '../../store/eventHub';

export default {
  data() {
    return {
      ago: 1,
    };
  },
  computed: {
    text() {
      if (this.ago === 0) {
        return 'Updating...';
      }

      return `Last updated ${this.ago} seconds ago`;
    },
  },
  mounted() {
    setInterval(() => {
      this.ago = (this.ago + 1) % 60;

      if (this.ago === 0) {
        eventHub.$emit(EVENTS.REFRESH_REQUESTED);
      }
    }, 1000);
  },
};
</script>

<template>
  <div class="d-flex align-center last-updated">
    <span>{{ text }}</span>
  </div>
</template>

<style lang="scss">
.last-updated {
  font-size: 11px;
  margin-right: 8px;
}

@media only screen and (max-width: 550px)  {
  .last-updated {
    display: inline-block;
    max-width: 100px;
    text-align: right;
  }
}

@media only screen and (max-width: 420px)  {
  .v-toolbar__title {
    display: none;
  }
}
</style>
