<script>
import LastUpdatedAt from '../layout/LastUpdatedAt.vue';
import eventHub, { EVENTS } from '../../store/eventHub';
import { fetchBranches, createDeployment } from '../../firebase/api';
import { convertEtaToDate } from '../../helpers/date';

export default {
  components: {
    LastUpdatedAt,
  },
  data() {
    return {
      isModalVisible: false,
      isDatePickerVisible: false,
      isTimePickerVisible: false,
      isDeploying: false,
      isDeployed: false,
      isSnackbarVisible: false,
      environments: ['QA', 'CC', 'Rocks'],
      errorMessage: '',
      branches: {
        isLoading: true,
        list: [],
      },
      form: {
        env: '',
        ref: '',
        pr: '',
        task: '',
        etaDate: '',
        etaTime: '',
      },
    };
  },
  methods: {
    deploy() {
      if (!this.form.env || !this.form.ref || !this.form.etaDate || !this.form.etaTime || !this.form.pr || !this.form.task) {
        this.isSnackbarVisible = true;
        this.errorMessage = 'Some data is missing. Please complete all required fields';

        return;
      }

      this.isDeploying = true;
      const eta = convertEtaToDate(this.form.etaDate, this.form.etaTime);

      createDeployment({ ...this.form, eta })
        .then(() => {
          this.isDeployed = true;
          eventHub.$emit(EVENTS.REFRESH_REQUESTED);
        })
        .finally(() => {
          this.isDeploying = false;
        });
    },
  },
  created() {
    fetchBranches()
      .then((data) => {
        this.branches.list = data;
        this.branches.isLoading = false;
      });
  },
};
</script>

<template>
  <v-row justify="end" class="pr-3">
    <last-updated-at />
    <v-dialog
      v-model="isModalVisible"
      max-width="640"
      persistent
    >
      <template #activator="{ on, attrs }">
        <v-btn
          dark
          color="grey darken-4"
          elevation="1"
          large
          v-bind="attrs"
          v-on="on"
        >
          <img src="/images/icons/spaceship-light.svg" class="mr-3" />
          DEPLOY
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          light
          color="grey lighten-4"
          elevation="1"
        >
          <v-toolbar-title class="font-weight-medium">
            Create deployment
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            light
            @click="isModalVisible = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid class="mt-4 pb-7">
          <v-row v-if="isDeployed" justify="center">
            <img src="/images/illustrations/deploy/deploy.svg" width="420" />
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-combobox
                v-model="form.env"
                label="Target environment"
                :items="environments"
                hide-no-data
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="form.ref"
                :loading="branches.isLoading"
                :items="branches.list"
                label="Select your branch to deploy"
                cache-items
                hide-no-data
                hide-details
                outlined
                dense
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.pr"
                label="GitHub PR Link"
                outlined
                hide-no-data
                hide-details
                dense
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.task"
                label="Jira Task Link"
                outlined
                hide-no-data
                hide-details
                dense
              />
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="isDatePickerVisible"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.etaDate"
                        label="ETA (Date)"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        hide-no-data
                        hide-details
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="form.etaDate"
                      first-day-of-week="1"
                      :min="new Date().toISOString()"
                      @input="isDatePickerVisible = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    ref="menu"
                    v-model="isTimePickerVisible"
                    :close-on-content-click="false"
                    :return-value.sync="form.etaTime"
                    transition="scale-transition"
                    offset-y
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.etaTime"
                        label="ETA (Time)"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        outlined
                        hide-no-data
                        hide-details
                        dense
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-time-picker
                      v-if="isTimePickerVisible"
                      v-model="form.etaTime"
                      format="24hr"
                      :min="`${new Date().getHours()}:${new Date().getMinutes()}`"
                      :allowed-minutes="(m) => m % 5 === 0"
                      @click:minute="$refs.menu.save(form.etaTime)"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="grey darken-4"
                large
                class="white--text"
                :loading="isDeploying"
                :disabled="isDeploying"
                @click="deploy"
              >
                <img src="/images/icons/spaceship-light.svg" class="mr-3" />
                DEPLOY
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-snackbar
        v-model="isSnackbarVisible"
      >
        {{ errorMessage }}
        <template #action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="isSnackbarVisible = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </v-row>
</template>
