export const setUpGoogleAuthProvider = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  provider.setCustomParameters({
    login_hint: 'user@superpeer.com',
  });

  return provider;
};

export const openAuthPopupWithProvider = (provider) => {
  return firebase.auth().signInWithPopup(provider);
};

export const loginWithGoogle = () => {
  const googleAuthProvider = setUpGoogleAuthProvider();

  return openAuthPopupWithProvider(googleAuthProvider);
};
