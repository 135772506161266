<script>
import SummaryCard from './SummaryCard.vue';
import { fetchEnvironments } from '../../firebase/api';
import eventHub, { EVENTS } from '../../store/eventHub';

export default {
  components: {
    SummaryCard,
  },
  data() {
    return {
      isLoading: false,
      environments: [],
    };
  },
  computed: {
    environmentsByKey() {
      return this.environments.reduce((acc, item) => {
        acc[item.env] = item;

        return acc;
      }, {});
    },
  },
  methods: {
    fetch() {
      fetchEnvironments()
        .then(({ data }) => {
          this.environments = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;

    this.fetch();

    eventHub.$on(EVENTS.REFRESH_REQUESTED, this.fetch);
  },
};
</script>

<template>
  <v-row>
    <summary-card
      env="QA"
      :is-loading="isLoading"
      :env-config="environmentsByKey.QA || {}"
    />
    <summary-card
      env="CC"
      :is-loading="isLoading"
      :env-config="environmentsByKey.CC || {}"
    />
    <summary-card
      env="ROCKS"
      :is-loading="isLoading"
      :env-config="environmentsByKey.Rocks || {}"
    />
  </v-row>
</template>

<style lang="scss" scoped>
.chip {
  font-size: 10px;
}

.artwork {
  position: absolute;
  top: 0;
  right: 0;
  width: 128px;
}
</style>
