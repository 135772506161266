<script>
import { auth } from '../services/auth';

export default {
  methods: {
    handleAuth() {
      auth();
    },
  },
};
</script>

<template>
  <div class="auth-view d-flex align-center">
    <div class="logo-section">
      <img
        src="https://superpeer.com/images/brandassets/superpeer-logo-hand.png"
        height="32"
        class="logo"
      />
      <h2 class="d-inline">
        Launch Control
      </h2>
      <div class="mt-8">
        <v-btn
          large
          dark
          color="grey darken-4"
          @click="handleAuth"
        >
          <v-icon left>
            mdi-google
          </v-icon>
          Login with Google
        </v-btn>
      </div>
    </div>
    <div class="artwork">
      <img src="/images/illustrations/login/login.svg" width="520" />
    </div>
  </div>
</template>

<style lang="scss">
.v-application.AuthView {
  overflow-x: hidden;

  .v-application--wrap {
    justify-content: center;
    align-items: center;
  }
}

.auth-view {
  justify-content: space-evenly;
  max-width: 800px;

  .logo-section {
    text-align: center;
    margin-right: 120px;

    .logo {
      position: relative;
      top: 6px;
    }
  }
}

@media only screen and (max-width: 880px)  {
  .v-application.AuthView {
    .v-application--wrap {
      margin-top: -40px;
    }
  }

  .artwork {
    img {
      max-width: calc(100vw - 40px);
    }
  }

  .auth-view {
    flex-direction: column-reverse;

    .logo-section {
      margin: 40px 0 0;
    }
  }
}
</style>
