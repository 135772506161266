import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AuthView from '../views/AuthView.vue';
import { isLoggedIn } from '../services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/auth',
    name: 'AuthView',
    component: AuthView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const loggedIn = isLoggedIn();

  if (loggedIn && to.name === 'AuthView') {
    return next({ name: 'HomeView' });
  }

  if (!loggedIn && to.name !== 'AuthView') {
    return next({ name: 'AuthView' });
  }

  return next();
});

export default router;
