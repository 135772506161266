import { loginWithGoogle } from '../firebase/auth';
import * as firebaseApi from '../firebase/api';

const saveAccountDataToLocalStorage = (authData) => {
  localStorage.setItem('AUTH_OBJECT', JSON.stringify(authData));
};

/**
 * @param { uid, displayName, email, photoUrl } authData
 */
const saveAccountData = (authData) => {
  saveAccountDataToLocalStorage(authData);
  firebaseApi.saveAccountData(authData);
};

export const getAuthData = () => {
  return JSON.parse(localStorage.getItem('AUTH_OBJECT'));
};

export const isLoggedIn = () => {
  const authObj = localStorage.getItem('AUTH_OBJECT');

  return Boolean(authObj && JSON.parse(authObj).uid);
};

export const auth = () => {
  return loginWithGoogle()
    .then((result) => {
      const { uid, displayName, email, photoURL } = result.user;

      return saveAccountData({
        uid,
        profile: { displayName, email, photoUrl: photoURL },
      });
    })
    .then(() => {
      window.app.$router.push({ name: 'HomeView' });
    });
};

export const registerAuthStateListener = () => {
  firebase.auth().onAuthStateChanged((authObj) => {
    if (authObj) {
      saveAccountDataToLocalStorage(authObj);
    } else {
      localStorage.removeItem('AUTH_OBJECT');

      if (document.location.pathname !== '/auth') {
        document.location = '/auth';
      }
    }
  });
};
