export const formatDate = (date) => {
  const intl = new Intl.DateTimeFormat('en-GB', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
  });

  return intl.format(new Date(date));
};

export const convertEtaToDate = (date, time) => {
  const str = `${date} ${time}:00`;
  const [dateValues, timeValues] = str.split(' ');
  const [year, month, day] = dateValues.split('-');
  const [hours, minutes, seconds] = timeValues.split(':');

  return new Date(+year, month - 1, +day, +hours, +minutes, +seconds).toISOString();
};
