export default () => {
  firebase.initializeApp({
    apiKey: 'AIzaSyBKlxaXtb0k7WfibHkp7kKsecO-jmU9uhQ',
    authDomain: 'superpeer-lc.firebaseapp.com',
    databaseURL: 'https://superpeer-lc-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'superpeer-lc',
    storageBucket: 'superpeer-lc.appspot.com',
    messagingSenderId: '960058665306',
    appId: '1:960058665306:web:6fdde64c9a5b179cc2eb63',
  });

  if (window.location.hostname === 'localhost') {
    firebase.functions().useEmulator('localhost', 5002);
  }
};
