<script>
import { fetchDeployments } from '../../firebase/api';
import { formatDate } from '../../helpers/date';
import eventHub, { EVENTS } from '../../store/eventHub';

export default {
  data() {
    return {
      headers: [
        { text: 'Environment', value: 'env' },
        { text: 'Branch', value: 'ref', sortable: false },
        { text: 'Deployed By', value: 'account.displayName' },
        { text: 'Deployed At', value: 'createdAt' },
        { text: 'ETA', value: 'eta' },
        { text: 'Links', value: 'links', sortable: false },
      ],
      isLoading: false,
      deployments: [],
    };
  },
  methods: {
    getEnvironmentConfig(env) {
      const envUrls = {
        QA: { url: 'https://superpeer.qa', color: 'blue' },
        CC: { url: 'https://superpeer.cc', color: 'red' },
        Rocks: { url: 'https://superpeer.rocks', color: 'green' },
      };

      return envUrls[env];
    },
    fetch() {
      fetchDeployments()
        .then(({ data }) => {
          this.deployments = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    openLink(link) {
      window.open(link);
    },
    formatDate,
  },
  created() {
    this.isLoading = true;

    this.fetch();

    eventHub.$on(EVENTS.REFRESH_REQUESTED, this.fetch);
  },
};
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="deployments"
    :items-per-page="50"
    :loading="isLoading"
    loading-text="Fetching deployments..."
    class="elevation-1"
  >
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template #item.env="{ item }">
      <v-chip
        small
        link
        dark
        :color="getEnvironmentConfig(item.env).color"
        class="env-chip"
      >
        <a
          :href="getEnvironmentConfig(item.env).url"
          target="_blank"
          rel="noopener noreferrer"
          class="chip-link"
        >
          {{ item.env }}
        </a>
      </v-chip>
    </template>

    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template #item.ref="{ item }">
      <a
        :href="`https://github.com/superpeer/superpeer/tree/${item.ref}`"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ item.ref }}
      </a>
    </template>

    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template #item.eta="{ item }">
      <span>
        {{ formatDate(item.eta) }}
      </span>
    </template>

    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template #item.createdAt="{ item }">
      <span>
        {{ formatDate(item.createdAt) }}
      </span>
    </template>

    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template #item.links="{ item }">
      <v-icon
        left
        @click="openLink(item.pr)"
      >
        mdi-github
      </v-icon>
      <v-icon
        color="blue"
        @click="openLink(item.task)"
      >
        mdi-jira
      </v-icon>
    </template>
  </v-data-table>
</template>

<style lang="scss">
.env-chip {
  .chip-link {
    color: #fff;
    text-decoration: none;
  }
}
</style>
