const PERMISSION_DENIED = 'permission-denied';

const callFunction = (fnName, data) => {
  return firebase.functions()
    .httpsCallable(fnName)(data)
    .catch((e) => {
      if (e.code === PERMISSION_DENIED) {
        firebase.auth().signOut();
      }
    });
};

export const saveAccountData = (data) => {
  return callFunction('saveAccountData', data);
};

export const fetchBranches = () => {
  return callFunction('fetchBranches')
    .then(({ data }) => {
      return data;
    });
};

export const createDeployment = (params) => {
  return callFunction('createDeployment', params);
};

export const updateEnvironment = (params) => {
  return callFunction('updateEnvironment', params);
};

export const fetchDeployments = () => {
  return callFunction('fetchDeployments');
};

export const fetchEnvironments = () => {
  return callFunction('fetchEnvironments');
};
